import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/adtopografica-site/adtopografica-site/src/components/LayoutMdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`MONITORAGGIO DEL TERRITORIO`}</h3>
    <p>{`  Si effettuano operazioni di videoispezione e di rilevamento fotogrammetrico di manufatti in condizioni di emergenza o in situazioni di rischio per gli operatori.`}</p>
    <ul>
      <li parentName="ul">{`Studi volumetrici su cave (Spianamenti,ecc),`}</li>
      <li parentName="ul">{`Planimetrie quotate e sezioni di tracciati stradali,`}</li>
      <li parentName="ul">{`Ispezioni Edili (Ponti, viadotti, tetti, ecc),`}</li>
      <li parentName="ul">{`Rilevamenti speciali (Elettrodotti, Pannelli Fotovoltaici),`}</li>
      <li parentName="ul">{`Agricoltura di precisione,`}</li>
      <li parentName="ul">{`Monitoraggio ambientale per indagini idrogeologiche,`}</li>
      <li parentName="ul">{`Monitoraggio dello stato di avanzamento dei lavori nei cantieri edili
(infrastrutture e fabbricati),`}</li>
      <li parentName="ul">{`Ispezione del territorio in relazione a catastrofi di origine naturale o antropica
(terremoti, alluvioni, frane, incendi, ecc)`}</li>
    </ul>
    <h3>{`FOTOGRAMMETRIA`}</h3>
    <p>{`  Si realizzano elaborati cartografici in grande scala, ortofotografie e DTM (modelli digitali del terreno) a partire dall’elaborazione di immagini fotografiche acquisite secondo piani di volo preprogrammati.`}</p>
    <p>{`  Le ortofoto rappresentano la proiezione ortogonale sul piano orizzontale del modello tridimensionale del terreno e possono essere utilizzate come base cartografica o strumento di misura.`}</p>
    <p>{`  Nella fase di post-elaborazione è possibile estrapolare sezioni, profili topografici e curve di livello a partire dalle ortofoto, dalla generazione di modelli DEM o in ambiente CAD.`}</p>
    <h3>{`Realizziamo video e foto aeree ideali per lo sviluppo rendering e per pubblicità.`}</h3>
    <p>{`  Siamo a disposizione per qualsiasi tipo di evento (eventi sportivi, riprese giornalistiche, riprese paesaggistiche e naturalistiche, documentari).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      